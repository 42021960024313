
















import { Picker, Popup, Search } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface Platform {
  id: number;
  name: string;
}

@Component({
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Search.name]: Search
  }
})
export default class PlatformPicker extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop({ default: 0 }) defaultId!: number;

  fullList = [] as Platform[];
  list = [] as Platform[];
  loading = true;
  keyword = "";
  defaultIndex = 0;

  get show() {
    return this.value;
  }
  set show(val) {
    this.$emit("input", val);
  }

  @Watch("show")
  onShow(show: boolean) {
    if (show && this.list.length === 0) {
      this.loadData();
    }
  }
  loadData() {
    this.loading = true;
    const requestData = {
      keyword: this.keyword
    };
    this.$axios
      .post("/api/manage/getPlatformListForPick", requestData)
      .then(res => {
        this.list = res.data.data;
        this.fullList = res.data.data;
        if (this.defaultId != 0) {
          this.defaultIndex = this.list.findIndex(v => {
            return this.defaultId == v.id;
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  searchData() {
    this.list = this.fullList.filter(v => v.name.indexOf(this.keyword) !== -1);
  }
  onPick(value: Platform) {
    this.$emit("onPick", {
      id: value.id,
      name: value.name
    });
    this.show = false;
  }
}
