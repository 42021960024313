





















import { Button, Dialog, Popup, Uploader } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import LocationPicker from "@/components/picker/location-picker.vue";
import PlatformPikcer from "@/components/picker/platform-picker.vue";
import BusinessPicker from "@/components/picker/business-picker.vue";
import SitePicker from "@/components/picker/site-picker.vue";
import AddressPicker from "@/views/user/address/address.vue";
import weui from "weui.js";
import "weui";

@Component({
  components: {
    [Button.name]: Button,
    [Popup.name]: Popup,
    [LocationPicker.name]: LocationPicker,
    [PlatformPikcer.name]: PlatformPikcer,
    [BusinessPicker.name]: BusinessPicker,
    [SitePicker.name]: SitePicker,
    [Uploader.name]: Uploader,
    AddressPicker
  }
})
export default class Sample extends Vue {
  showLocationPicker = false;
  showBusinessPicker = false;
  showSitePicker = false;
  showPlatformPicker = false;
  showAddressPicker = false;

  created() {
    this.$readyWx(this.$axios, ["scanQRCode"], true);
  }

  weuiPick() {
    weui.picker(
      [
        {
          label: "飞机票",
          value: 0,
          disabled: true // 不可用
        },
        {
          label: "火车票",
          value: 1
        },
        {
          label: "汽车票",
          value: 3
        },
        {
          label: "公车票",
          value: 4
        }
      ],
      {
        className: "custom-classname",
        container: "body",
        defaultValue: [3],
        onChange: function(result: { label: string }[]) {
          console.log(result);
        },
        onConfirm: function(result: { label: string }[]) {
          console.log(result);
        },
        id: "singleLinePicker"
      }
    );
  }

  getPlatform() {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //Android
    if (isAndroid) return "android";
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //iOS
    if (isiOS) return "ios";
  }

  scan() {
    Dialog.confirm({
      messageAlign: "left",
      message: `
      ua: ${navigator.userAgent}
      platform: ${this.getPlatform()}
      landUrl: ${localStorage.landUrl}
      currentUrl:  ${window.location.href.split("#")[0]}
      `
    }).then(() => {
      this.$scanCode(content => {
        alert(content);
      });
    });
  }

  onPickLocation(location: {}) {
    console.log(location);
  }

  onPickSite(location: {}) {
    console.log(location);
  }

  onPickAddress(location: {}) {
    console.log(location);
    this.showAddressPicker = false;
  }

  onPickFile(file: { file: File; status: string; key: string }) {
    if (Array.isArray(file)) {
      for (const f of file) {
        this.onPickFile(f);
      }
      return;
    }
    const data = new FormData();
    data.append("file", file.file);
    this.$axios.post("/api/manage/cabinet/uploadFile", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    /*
      @PostMapping("uploadFile")
      public ResultBean updateMark(@RequestParam String token, @RequestParam MultipartFile file) {
          System.out.println(file == null);
          System.out.println(token);
          return ResultBean.success();
      }
    */
  }
}
